import { Amount } from '@core/forms/validators/amount';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { IsNotEmpty, Validate } from 'class-validator';

export interface ITransferForm {
  amount: string;
  userId: string;
  saveRecipient: boolean;
}

export class TransferFormFields implements ITransferForm {
  @Validate(Amount, { message: t`Select source and enter amount` })
  amount: string;

  @IsNotEmpty({ message: t`Enter recepient ID` })
  userId: string;

  saveRecipient: boolean;
}

export const TransferResolver = classValidatorResolver(TransferFormFields);
