import React, { FC, useEffect, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import ConfirmTransfer from '@modules/new-private/orders/new-transfer/confirm-transfer/confirm-transfer';
import { TransferForm } from '@modules/new-private/orders/new-transfer/transfer-form';
import { Theme, useMediaQuery } from '@mui/material';
import { ErrorHandler } from '@shared/components/error-handler';
import { Loading } from '@shared/components/loading';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import { useNavigate } from '@shared/components/router';
import { Typography } from '@shared/components/typography';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';
import { reaction } from 'mobx';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { NewTransferViewModel } from '../new-transfer.vm';
import { Screen } from '../screens';

import { styles } from './new-transfer.styles';

export interface TransferProps extends AppWithStyles<typeof styles> {}

const TransferComponent: FC<TransferProps> = appObserver(({ classes }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);
  const { pathname } = useLocation();

  const checkUserStatusQuery = useQuery(['check-user-status'], () => $vm.checkUserStatus(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const GetConnectedDataQuery = useQuery(['get-connection-data'], () => $vm.getConnectedData(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const fetchDocumentQuery = useQuery(['fetch-document'], () => $vm.fetchDocument(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const $vm = React.useMemo(() => new NewTransferViewModel(), []);

  const [rate, setRate] = useState<number>($vm.rate);
  rate;

  useEffect(() => {
    $vm.checkUserStatus();
    reaction(
      () => $vm.rate,
      () => setRate($vm.rate),
    );
  }, []);

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        navigate(ROUTES.verification, { state: { redirect: pathname } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const onCloseKYCDialog = () => {
    setIsOpenKYCDialog(false);
  };

  if (GetConnectedDataQuery.isError) {
    return <ErrorHandler heading={<Typography variant="h3">{t`Please try again`}</Typography>} />;
  }
  if (
    checkUserStatusQuery.isFetching ||
    GetConnectedDataQuery.isFetching ||
    fetchDocumentQuery.isFetching
  ) {
    return (
      <div className={classNames(classes.invisibleContent, 'invisible-content')}>
        <Loading size={70} />
      </div>
    );
  }

  let form = <></>;
  if ($vm.activeScreen === Screen.CONFIRM_SCREEN) {
    form = <ConfirmTransfer vm={$vm} />;
  } else if ($vm.activeScreen === Screen.TRNSFER_FORM) {
    form = <TransferForm vm={$vm} isLoading={false} />;
  } else {
    form = <></>;
  }

  return (
    <div className={classes.root}>
      {form}
      <VerificationDialogDialog
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
      />
      <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
    </div>
  );
});
export default appWithStyles(styles)(TransferComponent);
