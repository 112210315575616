import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import ContectSupportIcon from '@shared/components/new-design/images/info-icon-white.svg';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up(Layout.laptop)]: {
        '& .MuiButton-outlinedPrimary': {
          color: `#fff !important`,
        },
      },
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '27px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    infoField: {
      width: '100%',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 20px',
    },
    generalInfoField: {
      width: '100%',
      height: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 20px',
    },
    fieldTitle: {
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    value: {
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    bold: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '170%',
      letterSpacing: '0.6px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    infoBlock: {},
    typeOfTransaction: {
      background: '#1E1D36',
      width: '100%',
      height: '60px',
      borderRadius: '12px 12px 0px 0px',
      padding: '10px 0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        color: '#fff',
      },
    },
    generalInfoBlock: {
      margin: '1px 0px',
      background: '#1E1D36',
      width: '100%',
      height: '185px',
      padding: '20px 0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        color: '#fff',
      },
    },
    total: {
      background: '#1E1D36',
      width: '100%',
      height: '60px',
      borderRadius: '0px 0px 12px 12px',
      padding: '10px 0px',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        color: '#fff',
      },
    },
    paymentMethod: {
      height: '60px',
      marginTop: '10px',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        borderRadius: '12px',
      },
    },
    footnote: {
      marginTop: '10px',
      color: '#8E93A4',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '170%',
      letterSpacing: '0.6px',
      [theme.breakpoints.up(Layout.laptop)]: {
        background: 'transparent',
        color: '#fff',
      },
    },
    fee: {
      marginTop: '15px',
    },
    recipient: {
      marginBottom: '10px',
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        background: '#412D68',
      },
    },
    hintButton: {
      width: '25px',
      height: '25px',
      position: 'absolute',
      marginLeft: '10px',
      cursor: 'pointer',
      background: `url(${ContectSupportIcon}) no-repeat left center`,
      right: '20px',
      top: '25px',
    },
  });
}
