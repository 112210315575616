import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import { ROUTES } from '@shared/constants/routes';

// eslint-disable-next-line import/namespace,import/default
import NewTransferForm from '../new-transfer-form/new-transfer';

import { styles } from './new-transfer-desktop.styles';

export interface NewTransferDesktopProps extends AppWithStyles<typeof styles> {}

const NewTransferDesktopComponent: FC<NewTransferDesktopProps> = ({ classes }) => {
  const routes = [
    { label: t`Accounts`, path: ROUTES.desktopPrivate.dashboard },
    { label: t`New transfer`, path: ROUTES.desktopPrivate.transfers.children.new },
  ];

  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.form}>
        <NewTransferForm />
      </div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(NewTransferDesktopComponent);
