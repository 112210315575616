import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormControlLabel, Theme } from '@mui/material';
import ContectSupportIcon from '@shared/components/new-design/images/info-icon-white.svg';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '& .MuiFormControlLabel-label': {
        color: _var(CSS_VARIABLES.colorPrimary100),
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '150%',
      },
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '27px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    rate: {
      color: _var(CSS_VARIABLES.colorPrimary300),
      fontSize: '14px',
      textAlign: 'center',
      fontWeight: 700,
      lineHeight: '170%',
      letterSpacing: '0.3px',
    },
    backButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    fields: {
      marginTop: '20px',
      marginBottom: '73px',
    },
    field: {
      width: '100%',
      '& + &': {
        marginTop: '1px',
        [theme.breakpoints.up(Layout.laptop)]: {
          marginTop: '16px',
        },
      },
    },
    amountBlock: {
      position: 'relative',
      marginBottom: '10px',
    },
    switchButton: {
      position: 'absolute',
      width: '41px',
      height: '41px',
      borderRadius: '50%',
      backgroundColor: '#7543FF',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    limitInfoBlock: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.2px',
      marginBottom: '12px',
      [theme.breakpoints.up(Layout.laptop)]: {
        marginTop: '12px',
      },
    },
    limitInfo: {
      color: '#8E94A4',
    },
    availableBlock: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.2px',
      marginBottom: '12px',
    },
    available: {
      color: '#D12F2F',
    },
    seeDetails: {
      color: '#7543FF',
      cursor: 'pointer',
      marginLeft: '2px',
    },
    limitInfoError: {
      color: '#D12F2F',
    },
    block: {
      margin: '0 0 25px',
      display: 'flex',
      width: '100%',
      marginTop: '14px',
      justifyContent: 'space-between',
    },
    inputRecepient: {
      marginTop: '16px',
      width: '100%',
      height: '68px',
    },
    recepientsList: {
      color: '#7543FF',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '150%',
      cursor: 'pointer',
    },
    hintButton: {
      width: '25px',
      height: '25px',
      position: 'absolute',
      marginLeft: '10px',
      cursor: 'pointer',
      background: `url(${ContectSupportIcon}) no-repeat left center`,
      right: '20px',
      top: '25px',
    },
    transferError: {
      color: '#C72C2C',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      marginBottom: '12px',
    },
  });
}

export const StyledLabel = appWithStyles(() => ({
  root: {
    marginLeft: 0,
  },
  label: {
    marginLeft: '10px',
    color: `${_var(CSS_VARIABLES.colorPrimary100)} !important`,
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
  },
}))(FormControlLabel);
