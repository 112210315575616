import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { TextField, Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '68px',
      borderRadius: '12px',
      border: '1px solid #7543FF',
      position: 'relative',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '0px 60px 0px 78px',
      transition: 'background-color 50ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:has(.Mui-focused)': {
        backgroundColor: '#1E1D36',
        [breakpoints.up(Layout.laptop)]: {
          backgroundColor: '#372753',
        },
      },
    },
    filled: {
      backgroundColor: '#1E1D36 !important',
      [breakpoints.up(Layout.laptop)]: {
        backgroundColor: '#372753 !important',
      },
    },
    errorBorder: {
      border: '1px solid #C72C2C !important',
    },
    readOnly: {
      border: 'none !important',
      backgroundColor: '#1E1D36 !important',
      [breakpoints.up(Layout.laptop)]: {
        backgroundColor: '#412D68 !important',
      },

      '& $inputLabel': {
        color: 'rgba(247, 249, 252, 0.40)',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.2px',
      },
      '& $id': {
        color: 'rgba(247, 249, 252, 0.40)',
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.2px',
      },
      '& $closeButton': {
        display: 'none',
      },
    },
    icon: {
      position: 'absolute',
      top: '8px',
      left: '8px',
    },
    loading: {
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    namePlaceholder: {
      color: '#757585',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '0.075px',
      [breakpoints.up(Layout.laptop)]: {
        color: '#fff',
      },
    },
    id: {
      color: '#FFF',
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.2px',
    },
    closeButton: {
      top: '19px',
      right: '12px',
      position: 'absolute',
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
    inputBlock: {
      width: 'calc(100% - 20px)',
    },
    nameBlock: {
      overflow: 'hidden',
    },
    inputLabel: {
      color: '#F7F9FC',
      fontSize: '12px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      overflow: 'hidden',
      textWrap: 'nowrap',
      '&::after': {
        content: ' ',
        display: 'block',
        width: 0,
        height: 0,
        lineHeight: 0,
      },
    },
    initials: {
      color: '#FFF',
      fontSize: '21px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    error: {
      color: '#C72C2C',
    },
    nameInput: {
      display: 'flex',
      marginTop: '4px',
    },
    input: {},
    userName: {
      color: '#FFF',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '160%',
      letterSpacing: '0.075px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textWrap: 'nowrap',

      '&::after': {
        content: ' ',
        display: 'block',
        width: 0,
        height: 0,
        lineHeight: 0,
      },
    },
    image: {
      width: '52px',
      height: '52px',
    },
  });
}

export const StyledTextField = appWithStyles((theme: Theme) => ({
  root: {
    border: 'none',
    background: 'transparent',
    outline: 'none',
    '& .MuiOutlinedInput-input': {
      border: 'none',
      outline: 'none',
      padding: '0px',
      width: '242px',
      paddingTop: '1px',

      color: '#FFF',
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.2px',

      '&::placeholder': {
        color: '#FFF',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.2px',
        opacity: 1,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: '0px !important',
    },
  },
  focused: {},
}))(TextField);
