import React, { FC, useMemo } from 'react';

import { appInject } from '@core/di/utils';
import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { TransferDetailsViewModel } from '@modules/new-private/orders/order-details-container/transfer-details.vm';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import { SmartCoinOrderStatusExternal } from '@shared/types/smart-coin-order-status';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';

import OrderDetails from '../order-details/order-details';
import { OrderDetailsViewModel } from './order-details.vm';
import { TransactionDetailsViewModel } from './transaction-details.vm';

import { styles } from './order-details-container.styles';

export interface DesktopOrderDetailsContainerProps extends AppWithStyles<typeof styles> {}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const DesktopOrderDetailsContainerComponent: FC<DesktopOrderDetailsContainerProps> = appObserver(
  ({ classes }) => {
    const smartCoinDetailsDialogVM = appInject<ISmartCoinDetailsDialogViewModel>(
      DI_TOKENS.smartCoinDetailsDialogViewModel,
    );

    const orderDetailsVM: OrderDetailsViewModel = useMemo(
      () => new OrderDetailsViewModel(),
      [smartCoinDetailsDialogVM.isOpenDetails],
    );
    const transactionDetailsVM: TransactionDetailsViewModel = useMemo(
      () => new TransactionDetailsViewModel(),
      [smartCoinDetailsDialogVM.isOpenDetails],
    );
    const transferDetailsVM: TransactionDetailsViewModel = useMemo(
      () => new TransferDetailsViewModel(),
      [smartCoinDetailsDialogVM.isOpenDetails],
    );

    const getActiveViewModel = ():
      | OrderDetailsViewModel
      | TransactionDetailsViewModel
      | TransactionDetailsViewModel => {
      switch (smartCoinDetailsDialogVM.type) {
        case OrderDetailsType.WITHDRAWAL_FIAT_COIN:
        case OrderDetailsType.DEPOSIT_FIAT_COIN:
          return transactionDetailsVM;
        case OrderDetailsType.SENDING_SMART_COIN:
        case OrderDetailsType.RECEIVING_SMART_COIN:
          return transferDetailsVM;
        default:
          return orderDetailsVM;
      }
    };

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

    let orderDetailsComponent = <></>;

    if (smartCoinDetailsDialogVM.orderId) {
      orderDetailsComponent = (
        <OrderDetails
          vm={getActiveViewModel()}
          type={smartCoinDetailsDialogVM.type}
          orderId={smartCoinDetailsDialogVM.orderId as string}
        />
      );
    } else {
      orderDetailsComponent = <></>;
    }

    let content = <></>;

    if (isMobile) {
      content = (
        <Drawer
          anchor={'bottom'}
          open={smartCoinDetailsDialogVM.isOpenDetails}
          className={classNames(classes.drawer, {
            [classes.executed as string]:
              getActiveViewModel().status &&
              getActiveViewModel().status === SmartCoinOrderStatusExternal.EXECUTED,
            [classes.inProgress as string]:
              getActiveViewModel().status &&
              getActiveViewModel().status === SmartCoinOrderStatusExternal.IN_PROGRESS,
            [classes.inProgress as string]:
              getActiveViewModel().status &&
              getActiveViewModel().status === SmartCoinOrderStatusExternal.SECURE_3D,
            [classes.canceled as string]:
              getActiveViewModel().status &&
              getActiveViewModel().status === SmartCoinOrderStatusExternal.CANCELED,
          })}
          onClose={() => smartCoinDetailsDialogVM.closeDialog()}
        >
          <div className={classes.drawerContent}>{orderDetailsComponent}</div>
          <CloseDrawerButton onClick={() => smartCoinDetailsDialogVM.hideDialog()} />
        </Drawer>
      );
    } else {
      content = (
        <Modal
          open={smartCoinDetailsDialogVM.isOpenDetails}
          onClose={() => smartCoinDetailsDialogVM.closeDialog()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>{orderDetailsComponent}</Box>
        </Modal>
      );
    }
    return <div className={classes.root}>{content}</div>;
  },
);
export default appWithStyles(styles)(DesktopOrderDetailsContainerComponent);
