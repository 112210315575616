import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { InputRecepient } from '@modules/new-private/orders/new-transfer/transfer-form/input-recepient';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { GetContactListModel } from '@shared/models/contacts/get-contact-model-model';
import { Layout } from '@shared/utils/layout';

import { styles } from './recipients-dialog.styles';

export interface RecipientsDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onSelectUser: (userId: string) => void;
  recipients: Array<GetContactListModel>;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const RecipientsDialogComponent: FC<RecipientsDialogProps> = ({
  classes,
  onClose,
  isOpen,
  recipients,
  onSelectUser,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const emptyState = (
    <div className={classes.emptyState}>
      <div
        className={classes.emptyStateText}
      >{t`It looks like you still have an empty list of recipients.`}</div>
    </div>
  );

  let recipientsList =
    recipients.length > 0 ? (
      <ul className={classes.recipientsList}>
        {recipients.map((r) => {
          return (
            <div className={classes.recipientItem} onClick={() => onSelectUser(r.asJson.id)}>
              <InputRecepient recipient={r} readOnly={true} />
            </div>
          );
        })}
      </ul>
    ) : (
      emptyState
    );

  const limitComponent = (
    <div className={classes.viewBlock}>
      <div className={classes.title}>{t`Recipient List`}</div>
      <div className={classes.details}>{recipientsList}</div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{limitComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{limitComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(RecipientsDialogComponent);
