import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      [theme.breakpoints.up(Layout.laptop)]: {
        borderRadius: '12px',
        width: '100%',
        height: 'auto',
        background: 'transparent',
      },
      [theme.breakpoints.down(Layout.tablet)]: {
        padding: '20px',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
      },
    },
    invisibleContent: {
      [theme.breakpoints.down(Layout.tablet)]: {
        height: '100%',
      },
    },
  });
}
