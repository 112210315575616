import { t } from '@lingui/macro';
import { BaseDetailsViewModel } from '@modules/new-private/orders/order-details-container/base-details.vm';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { minDigitsAfterDot } from '@shared/pipes';
import { transactionStatusToExternalStatus } from '@shared/pipes/transaction-status.pipe';
import { IDetailsDialogViewModel } from '@shared/types/details-dialog-vm';
import { formatDate } from '@shared/utils/date';
import { formatAmount } from '@shared/utils/metals';
import { BigNumber } from 'bignumber.js';

export class TransactionDetailsViewModel
  extends BaseDetailsViewModel
  implements IDetailsDialogViewModel
{
  constructor() {
    super();
  }

  get hasData() {
    return Boolean(this._data.transactionDetails);
  }

  get status() {
    return transactionStatusToExternalStatus(
      this._data.transactionDetails?.asJson.status as TransactionStatus,
    );
  }

  get isPaymentMethodIsCard() {
    return this.paymentMethod === 'Card';
  }

  get currency() {
    return this.transactionDetails?.asJson.currency || '';
  }

  get paymentMethod() {
    if (['Withdrawal', 'Deposit'].includes(this.transactionDetails?.asJson.type || ''))
      return 'Card';
    return this.transactionDetails?.asJson.paymentMethod || '';
  }

  get paymentMethodBrand() {
    return this.transactionDetails?.asJson.paymentMethodBrand || '';
  }

  get paymentMethodLast4Digs() {
    return this.transactionDetails?.asJson.paymentMethodSerialNumber || '';
  }

  get isBuy() {
    return (this._data.transactionDetails?.asJson.type as string) === 'Deposit';
  }

  get transactionType() {
    return this.isBuy ? t`Deposit` : t`Withdrawal`;
  }

  get quantity(): string {
    return minDigitsAfterDot(formatAmount(this._data.transactionDetails?.asJson.value || 0, 2), 2);
  }

  get coinName(): string {
    return this._data.transactionDetails?.asJson.currency || '';
  }

  get date() {
    return formatDate(this._data.transactionDetails?.asJson.date || new Date(), 'lll');
  }

  get declineTitle() {
    return t`Why this order was Canceled ?`;
  }

  get declineMessage() {
    return this._data.orderStatus?.asJson.declineMessage || '';
  }

  get rateTitle() {
    return this.isBuy ? `${t`Per`} 1 ${this.coinName}` : t`Withdrawal Rate`;
  }

  get rateValue() {
    return `1.00 ${this.coinName} = 1.00 ${
      this.productsService.getProductByCurrency(this.coinName)?.asJson.baseCurrency
    }`;
  }

  get price() {
    return `${minDigitsAfterDot(
      formatAmount(this._data.transactionDetails?.asJson.value || 0, 2),
    )} ${this.productsService.getProductByCurrency(this.coinName)?.asJson.baseCurrency}`;
  }

  get transactionFee() {
    return this._data.transactionDetails?.asJson.transactionFee || 0;
  }

  get transactionFeeTextValue() {
    return `${minDigitsAfterDot(formatAmount(this.transactionFee, 2))} ${
      this.productsService.getProductByCurrency(this.coinName)?.asJson.baseCurrency
    }`;
  }

  get totalAmountTextValue() {
    return this.isBuy
      ? `${minDigitsAfterDot(
          formatAmount(
            BigNumber(this._data.transactionDetails?.asJson.value || 0)
              .plus(this.transactionFee)
              .toFixed(2),
            2,
          ),
          2,
        )} ${this.productsService.getProductByCurrency(this.coinName)?.asJson.baseCurrency}`
      : `${minDigitsAfterDot(
          formatAmount(
            BigNumber(this._data.transactionDetails?.asJson.value || 0)
              .minus(this.transactionFee)
              .toFixed(2),
            2,
          ),
          2,
        )} ${this.productsService.getProductByCurrency(this.coinName)?.asJson.baseCurrency}`;
  }

  get id() {
    return this._data.transactionDetails?.asJson.id || '';
  }

  get blockchainTransactionId() {
    return '';
  }

  async fetchDetails(id: string): Promise<void> {
    this._data.transactionDetails = null;
    this._data.transactionDetails = await this.walletsService.getTransactionDetails(id);
  }
}
