import { t } from '@lingui/macro';
import { BaseDetailsViewModel } from '@modules/new-private/orders/order-details-container/base-details.vm';
import { TransactionStatus } from '@shared/models/transactions/transaction-status';
import { minDigitsAfterDot } from '@shared/pipes';
import { transactionStatusToExternalStatus } from '@shared/pipes/transaction-status.pipe';
import { IDetailsDialogViewModel } from '@shared/types/details-dialog-vm';
import { formatDate } from '@shared/utils/date';
import { formatAmount } from '@shared/utils/metals';

export class TransferDetailsViewModel
  extends BaseDetailsViewModel
  implements IDetailsDialogViewModel
{
  constructor() {
    super();
  }

  get hasData() {
    return Boolean(this._data.transactionDetails);
  }

  get status() {
    return transactionStatusToExternalStatus(
      this.transactionDetails?.asJson.status as TransactionStatus,
    );
  }

  get isValidPaymentMethod() {
    return false;
  }

  get currency() {
    return this.transactionDetails?.asJson.currency || '';
  }

  get paymentMethodLast4Digs() {
    return this.transactionDetails?.asJson.paymentMethodSerialNumber || '';
  }

  get isBuy() {
    return false;
  }

  get transactionType() {
    return t`Transfer`;
  }

  get quantity(): string {
    return minDigitsAfterDot(formatAmount(this._data.transactionDetails?.asJson.value || 0, 8), 2);
  }

  get coinName(): string {
    return this._data.transactionDetails?.asJson.currency || '';
  }

  get date() {
    return formatDate(this._data.transactionDetails?.asJson.date || new Date(), 'lll');
  }

  get declineTitle() {
    return t`Why transaction was Canceled ?`;
  }

  get declineMessage() {
    return this._data.transactionDetails?.asJson.failureMessage || '';
  }

  get transactionFee() {
    return 0;
  }

  get transactionFeeTextValue() {
    return `${minDigitsAfterDot(formatAmount(this.transactionFee, 2))} ${
      this.ratesVM.currency || ''
    }`;
  }

  get totalAmount() {
    return this._data.transactionDetails?.asJson.value || 0;
  }

  get totalAmountTextValue() {
    return `${minDigitsAfterDot(formatAmount(this.totalAmount, 8))} ${this.coinName || ''}`;
  }

  get id() {
    return this._data.transactionDetails?.asJson.id || '';
  }

  get blockchainTransactionId() {
    return this._data.transactionDetails?.asJson.blockchainTransactionId || '';
  }

  async fetchDetails(id: string): Promise<void> {
    this._data.transactionDetails = null;
    this._data.transactionDetails = await this.walletsService.getTransactionDetails(id);
  }

  async fetchUserDetails(): Promise<void> {
    if (!this._data.transactionDetails?.asJson.userId) return;
    this._data.userDetails = null;
    this._data.userDetails = await this.userService.getUserDetails(
      this._data.transactionDetails.asJson.userId,
    );
  }
}
