import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { NewTransferError } from '@modules/new-private/orders/new-transfer/new-transfer-form/new-transfers-errors';
import { NewTransferViewModel } from '@modules/new-private/orders/new-transfer/new-transfer.vm';
import { Screen } from '@modules/new-private/orders/new-transfer/screens';
import { InputRecepient } from '@modules/new-private/orders/new-transfer/transfer-form/input-recepient';
import { RecipientsListDialog } from '@modules/new-private/orders/new-transfer/transfer-form/recipients-dialog';
import { DocumentDialog } from '@modules/new-private/orders/purchase-and-sell/document-dialog';
import SwitchButtonIcon from '@modules/new-private/orders/purchase-and-sell/purchase-and-sell-form/img/switch-icon.svg';
import { TextFieldProps, Theme, useMediaQuery } from '@mui/material';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { InputCurrency } from '@shared/components/new-design/input-currency-text';
import SubmitButton from '@shared/components/new-design/submit-button';
import { AppSwitch } from '@shared/components/new-design/switch';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { Layout } from '@shared/utils/layout';
import { amountToNumber } from '@shared/utils/metals';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { ITransferForm, TransferFormFields, TransferResolver } from './transfer.validator';

import { StyledLabel, styles } from './transfer-form.styles';

export interface TransferFormProps extends AppWithStyles<typeof styles> {
  isLoading?: boolean;
  vm: NewTransferViewModel;
}

const TransferFormComponent: React.FC<TransferFormProps> = appObserver(
  ({ classes, isLoading, vm }) => {
    const $vm = useMemo(() => vm, []);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
    const navigate = useNavigate();

    const handleMaintenancePage = () => {
      isMobile
        ? navigate(ROUTES.mobilePrivate.maintenancePage)
        : navigate(ROUTES.desktopPrivate.maintenancePage);
    };

    const [isOpenDocumentDialog, setIsOpenDocumentDialog] = useState<boolean>(false);

    const {
      trigger,
      setValue,
      getValues,
      handleSubmit,
      formState: { isValid },
    } = useForm<TransferFormFields>({ resolver: TransferResolver });

    const validateRecipientMutation = useMutation(
      (data: { userId: string }) => $vm.validateRecipient(data.userId),
      {
        onSettled: () => {
          if ($vm.userError) {
            handleChandeSaveButton({}, false);
          }
        },
      },
    );

    const getUserErrorText = () => {
      switch ($vm.userError) {
        case NewTransferError.AMOUNT_GREATE_THAN_BALANCE:
          return t`You can\`t send more than on Your Balance`;
        case NewTransferError.BLOCKED:
        case NewTransferError.AWAIT_DELETION:
          return t`Transfer can\`t be sent to the Blocked User`;
        case NewTransferError.TRANSFER_TO_SELF:
          return t`Transfer can\`t be sent to yourself`;
        case NewTransferError.NOT_FOUND:
          return t`Please provide correct transfer details`;
        default:
          return '';
      }
    };

    const handleChandeSaveButton = (e: any, isSave: boolean) => {
      setIsSave(isSave);
      setValue(nameOf<ITransferForm>('saveRecipient'), isSave);
    };

    const [isOpenRecipientsList, setIsOpenRecipientsList] = useState<boolean>(false);
    const [isSave, setIsSave] = useState<boolean>(false);

    const smartCoinAmountInputRef = React.useRef<(TextFieldProps & { focus: () => void }) | null>(
      null,
    );
    const fiatCurrencyAmountInputRef = React.useRef<
      (TextFieldProps & { focus: () => void }) | null
    >(null);

    const [smartCoinAmount, setSmartCoinAmount] = useState<string>('');
    const [fiatCurrencyAmount, setFiatCurrencyAmount] = useState<string>('');

    const handleOnChangeAmount = (value: string) => {
      setSmartCoinAmount(value);
      setValue(nameOf<ITransferForm>('amount'), String(amountToNumber(value)));
      const sourceValue = $vm.calculateFiatCurrencyAmount(value);
      setFiatCurrencyAmount(sourceValue);
      trigger(nameOf<ITransferForm>('amount'));
      $vm.validateTransferSize(amountToNumber(value));
      if ($vm.serverError) {
        $vm.setUserError($vm.serverError);
        $vm.serverError = null;
      }
    };

    const handleChangeUser = (userId: string) => {
      validateRecipientMutation.mutate({ userId });
      setValue(nameOf<ITransferForm>('userId'), userId);
      trigger(nameOf<ITransferForm>('userId'));
    };

    const onSubmitFrom = () => {
      $vm.submitTransferForm({
        amount: amountToNumber(getValues().amount) || 0,
        orderId: $vm.getActiveWallet()?.walletId || '',
        recipientUserId: $vm.recipient.asJson.id,
        savedRecipient: getValues().saveRecipient,
      });
    };

    useEffect(() => {
      if ($vm.activeScreen === Screen.TRNSFER_FORM && $vm.createdOrder) {
        handleOnChangeAmount(`${$vm.createdOrder.amount}`);
        handleChandeSaveButton({}, $vm.createdOrder.savedRecipient);
        handleChangeUser($vm.createdOrder.recipientUserId);
        $vm.removeCreatedOrder();
      }
    }, [$vm.activeScreen]);

    const navigateToDashboard = () => {
      navigate(ROUTES.mobilePrivate.dashboard);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
      if (e.code === 'Enter' && isValid) {
        handleSubmit(onSubmitFrom)();
      }
    };

    const maintenanceModeBlock = (
      <div className={classes.availableBlock}>
        <span className={classes.available}>{t`System is under maintenance.`}</span>
        <span className={classes.seeDetails} onClick={handleMaintenancePage}>{t`See details`}</span>
      </div>
    );

    const activeWallet = $vm.getActiveWallet();

    return (
      <>
        <form
          onSubmit={handleSubmit(onSubmitFrom)}
          onKeyDown={handleKeyPress}
          className={classes.root}
        >
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToDashboard} />
            </div>
            <div className={classes.title}>{`${t`Transfer of`} ${
              activeWallet?.currency || ''
            }`}</div>
            {$vm.document ? (
              <div
                className={classes.hintButton}
                onClick={() => setIsOpenDocumentDialog(true)}
              ></div>
            ) : (
              <></>
            )}
            <div className={classes.fields}>
              <div className={classes.amountBlock}>
                <div className={classes.switchButton}>
                  <img src={String(SwitchButtonIcon)} />
                </div>
                <div className={classes.field}>
                  <InputCurrency
                    resetValidation={() => {}}
                    digitsCountAfterDot={8}
                    autoFocus={true}
                    disabled={$vm.isActiveMaintenanceMode}
                    error={$vm.userError === NewTransferError.AMOUNT_GREATE_THAN_BALANCE}
                    inputRef={smartCoinAmountInputRef}
                    currency={activeWallet?.currency || ''}
                    value={smartCoinAmount}
                    placeholder={'0'}
                    text={
                      activeWallet
                        ? `${minDigitsAfterDot(amountPipe(activeWallet.balance, 8))} ${
                            activeWallet.currency
                          }`
                        : ''
                    }
                    onChangeValue={handleOnChangeAmount}
                    key={'amount'}
                  />
                </div>
                <div className={classes.field}>
                  <InputCurrency
                    resetValidation={() => {}}
                    inputRef={fiatCurrencyAmountInputRef}
                    value={fiatCurrencyAmount}
                    disabled={true}
                    text={`1 ${$vm.getActiveWallet()?.currency} = ${
                      $vm.currency
                    } ${minDigitsAfterDot(amountPipe($vm.rate))}`}
                    currency={$vm.currency || ''}
                    onChangeValue={() => {}}
                    key={'fiatCurrencyAmount'}
                  />
                </div>
              </div>
              <div className={classes.inputRecepient}>
                <InputRecepient
                  isLoading={validateRecipientMutation.isLoading}
                  onChangeUser={handleChangeUser}
                  onClearData={() => $vm.removeRecepient()}
                  recipient={$vm.recipient}
                  disabled={$vm.isActiveMaintenanceMode}
                  error={
                    $vm.userError &&
                    [
                      NewTransferError.TRANSFER_TO_SELF,
                      NewTransferError.NOT_FOUND,
                      NewTransferError.BLOCKED,
                      NewTransferError.AWAIT_DELETION,
                    ].includes($vm.userError)
                      ? $vm.userError
                      : undefined
                  }
                />
              </div>
              <div className={classes.block}>
                <StyledLabel
                  control={
                    <AppSwitch
                      disabled={$vm.isDisabledSaveToggle}
                      checked={isSave}
                      onChange={handleChandeSaveButton}
                    />
                  }
                  label={t`Save recipient`}
                />
                <div
                  className={classes.recepientsList}
                  onClick={() =>
                    $vm.isActiveMaintenanceMode ? () => {} : setIsOpenRecipientsList(true)
                  }
                >{t`List of recipients`}</div>
              </div>
            </div>
          </div>
          <div>
            {$vm.isActiveMaintenanceMode ? (
              maintenanceModeBlock
            ) : (
              <div className={classes.transferError}>{getUserErrorText()}</div>
            )}
            <SubmitButton
              label={t`Continue`}
              disabled={
                $vm.isConfirmationBlocked ||
                !isValid ||
                !$vm.recipient ||
                $vm.isActiveMaintenanceMode
              }
              isLoading={isLoading}
              onSubmit={handleSubmit(onSubmitFrom)}
            />
          </div>
        </form>
        <RecipientsListDialog
          isOpen={isOpenRecipientsList}
          onClose={() => setIsOpenRecipientsList(false)}
          onSelectUser={(userId: string) => {
            handleChangeUser(userId);
            setIsOpenRecipientsList(false);
          }}
          recipients={$vm.contacts}
        />
        <DocumentDialog
          isOpen={isOpenDocumentDialog}
          onClose={() => setIsOpenDocumentDialog(false)}
          title={t`Transaction Information`}
          type={DocumentsTitle.TRANSFER}
          url={$vm.document?.url || ''}
        />
      </>
    );
  },
);

export default appWithStyles(styles)(TransferFormComponent);
