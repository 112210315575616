import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '475px',
      position: 'relative',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
    },
    description: {
      color: 'rgba(255, 255, 255, 0.60)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      width: '315px',
      marginTop: '24px',
    },
    details: {
      marginTop: '28px',
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '180%',
      letterSpacing: '0.3px',
      marginBottom: '75px',
      width: '100%',
    },
    bold: {
      fontWeight: 600,
    },
    drawer: {
      '& .MuiPaper-root': {
        maxHeight: '90%',
        height: 'auto',
        background: '#161524',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    emptyState: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 20%',
      height: '345px',
    },
    emptyStateText: {
      color: '#79818F',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
      letterSpacing: '0.2px',
    },
    recipientsList: {
      paddingLeft: '0px !important',
    },
    recipientItem: {
      cursor: 'pointer',
      '& + &': {
        marginTop: '6px',
      },
    },
  });
}
