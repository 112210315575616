import React, { ChangeEvent, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { NewTransferError } from '@modules/new-private/orders/new-transfer/new-transfer-form/new-transfers-errors';
import { InputAdornment } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { GetContactListModel } from '@shared/models/contacts/get-contact-model-model';
import { UserDetailsModel } from '@shared/models/users/details-model';
import classNames from 'classnames';

import CloseIcon from './img/close-button.svg';
import EmptyIcon from './img/empty-icon.svg';
import NotFoundIcon from './img/not-found.svg';

import { StyledTextField, styles } from './input-recepient.styles';

export interface InputRecepientProps extends AppWithStyles<typeof styles> {
  onChangeUser?: (userId: string) => void;
  onClearData?: () => void;
  isLoading?: boolean;
  recipient?: UserDetailsModel | GetContactListModel;
  disabled?: boolean;
  error?: NewTransferError;
  readOnly?: boolean;
}

const InputRecepientComponent: React.FC<InputRecepientProps> = ({
  classes,
  onClearData,
  onChangeUser,
  isLoading,
  recipient,
  error,
  disabled,
  readOnly,
}) => {
  const [userId, setUserId] = useState<string>(recipient ? recipient.asJson.id : '');

  const clearRecepient = () => {
    setUserId('');
    onClearData && onClearData();
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserId(e.target.value);
    if (
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
        e.target.value,
      )
    ) {
      onChangeUser && onChangeUser(e.target.value);
    }
  };

  let closeButton =
    Boolean(recipient) || error ? (
      <div className={classes.closeButton} onClick={clearRecepient}>
        <img src={String(CloseIcon)} />
      </div>
    ) : (
      <></>
    );

  const getAndronment = () => {
    return (
      <InputAdornment
        position="start"
        style={{
          position: 'relative',
        }}
      >
        <div className={classes.id}>{t`ID:`}</div>
      </InputAdornment>
    );
  };

  let inputElement = (
    <div className={classes.inputBlock}>
      <StyledTextField
        className={classes.input}
        autoComplete={'off'}
        onChange={handleChange}
        variant={'outlined'}
        type={'text'}
        value={userId}
        disabled={disabled}
        InputProps={{
          startAdornment: getAndronment(),
        }}
        placeholder={t`Search for Recipient by ID`}
      />
    </div>
  );

  let userIcon = (
    <div className={classes.icon}>
      <img src={String(EmptyIcon)} />
    </div>
  );

  let namePlaceholder = <div className={classes.namePlaceholder}>{t`Recipient Name`}</div>;

  if (recipient) {
    if (recipient.asJson.avatarUrl) {
      userIcon = (
        <div className={classes.icon}>
          <div
            style={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              overflow: 'hidden',
              backgroundColor: `${recipient.asJson.systemColor}`,
            }}
          >
            <img className={classes.image} src={recipient.asJson.avatarUrl} />
          </div>
        </div>
      );
    } else {
      userIcon = (
        <div className={classes.icon}>
          <div
            style={{
              width: '52px',
              height: '52px',
              borderRadius: '50%',
              backgroundColor: `${recipient.asJson.systemColor}`,
            }}
          >
            <div className={classes.initials}>{recipient.asJson.initials}</div>
          </div>
        </div>
      );
    }
    namePlaceholder = (
      <div className={classes.userName}>
        <span>{recipient.asJson.systemName}</span>
      </div>
    );
    inputElement = (
      <div className={classes.inputBlock}>
        <div className={classes.inputLabel}>{recipient.asJson.id}</div>
      </div>
    );
  }

  if (error === NewTransferError.NOT_FOUND) {
    userIcon = (
      <div className={classes.icon}>
        <img src={String(NotFoundIcon)} />
      </div>
    );
    namePlaceholder = (
      <div className={classes.namePlaceholder}>
        <span className={classes.error}>{t`Recipient not found`}</span>
      </div>
    );
    inputElement = (
      <div className={classes.inputBlock}>
        <div className={classes.inputLabel}>{userId}</div>
      </div>
    );
  } else if (error === NewTransferError.BLOCKED) {
    namePlaceholder = (
      <div className={classes.userName}>
        <span>{recipient?.asJson.systemName || ''}</span>
        <span className={classes.error}>({t`blocked`})</span>
      </div>
    );
  } else if (error === NewTransferError.TRANSFER_TO_SELF) {
    namePlaceholder = (
      <div className={classes.userName}>
        <span>{recipient?.asJson.systemName || ''}</span>
        <span className={classes.error}>({t`you`})</span>
      </div>
    );
  }

  if (isLoading) {
    userIcon = (
      <div className={classes.loading}>
        <Loading size={30} />
      </div>
    );
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.filled as string]: Boolean(recipient) || Boolean(error),
        [classes.readOnly as string]: readOnly,
        [classes.errorBorder as string]: Boolean(error),
      })}
    >
      {userIcon}
      <div className={classes.nameBlock}>
        {namePlaceholder}
        <div className={classes.nameInput}>{inputElement}</div>
      </div>
      {closeButton}
    </div>
  );
};

export default appWithStyles(styles)(InputRecepientComponent);
