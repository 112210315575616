import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {},
    executed: {},
    inProgress: {},
    canceled: {},
    drawer: {
      '& .MuiPaper-root': {
        height: 'auto',
        maxHeight: '90%',
        paddingTop: '30px',
        borderRadius: '21px 21px 0px 0px',
        borderTop: '2px solid #7543FF',
        background: '#161524',
        backdropFilter: 'blur(50px)',
      },
      '&$executed .MuiPaper-root': {
        borderTop: `2px solid ${_var(CSS_VARIABLES.colorSuccess300)} !important`,
      },
      '&inProgress .MuiPaper-root': {
        borderTop: `2px solid ${_var(CSS_VARIABLES.colorSecondary200)} !important`,
      },
      '&canceled .MuiPaper-root': {
        borderTop: `2px solid ${_var(CSS_VARIABLES.colorError500)} !important`,
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
  });
}
