import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import copyIcon from '@shared/components/new-design/images/copy-grey.svg';
import DepositIcon from '@shared/components/new-design/images/deposit-details.svg';
import PurchaseIcon from '@shared/components/new-design/images/purchase-details.svg';
import ReceiveIcon from '@shared/components/new-design/images/receive-details.svg';
import SelloutIcon from '@shared/components/new-design/images/sell-details.svg';
import SentIcon from '@shared/components/new-design/images/sent-details.svg';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      padding: '20px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    statusIconBlock: {
      display: 'flex',
      justifyContent: 'center',
    },
    executed: {},
    failed: {},
    inProgress: {},
    canceled: {},
    purchase: {},
    deposit: {},
    sellout: {},
    sent: {},
    receive: {},
    statusIconBackground: {
      width: '64px',
      height: '64px',
      borderRadius: '50%',
      '$purchase &': {
        background: `url(${PurchaseIcon}) no-repeat center center`,
      },
      '$deposit &': {
        background: `url(${DepositIcon}) no-repeat center center`,
      },
      '$sellout &': {
        background: `url(${SelloutIcon}) no-repeat center center`,
      },
      '$sent &': {
        background: `url(${SentIcon}) no-repeat center center`,
      },
      '$receive &': {
        background: `url(${ReceiveIcon}) no-repeat center center`,
      },
      '$executed &': {
        backgroundColor: _var(CSS_VARIABLES.colorSuccess300),
      },
      '$failed &': {
        backgroundColor: _var(CSS_VARIABLES.colorGrey400),
      },
      '$inProgress &': {
        backgroundColor: _var(CSS_VARIABLES.colorSecondary200),
      },
      '$canceled &': {
        backgroundColor: _var(CSS_VARIABLES.colorError500),
      },
    },
    statusIconImage: {},
    operationAmount: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '150%',
      textAlign: 'center',
      marginTop: '16px',
    },
    dateTime: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.4px',
      textAlign: 'center',
      marginTop: '10px',
    },
    currentStatusBlock: {
      marginTop: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '48px',
      padding: '0px 12px',
    },
    currentStatusFieldName: {
      color: _var(CSS_VARIABLES.colorPrimary300),
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '165%',
      letterSpacing: '0.2px',
    },
    currentStatusFieldValue: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '165%',
      letterSpacing: '0.2px',
      '$executed &': {
        color: _var(CSS_VARIABLES.colorSuccess300),
      },
      '$failed &': {
        color: _var(CSS_VARIABLES.colorGrey400),
      },
      '$inProgress &': {
        color: _var(CSS_VARIABLES.colorSecondary200),
      },
      '$canceled &': {
        color: _var(CSS_VARIABLES.colorError500),
      },
    },
    currentStatusFieldValueAndInfo: {
      display: 'flex',
    },
    typeOfTransaction: {
      background: '#1E1D36',
      width: '100%',
      height: '60px',
      borderRadius: '12px 12px 0px 0px',
      padding: '10px 0px',
      '& $value': {
        display: 'flex',
        padding: '6px 14px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        borderRadius: '30px',
        background: '#363453',
        color: 'rgba(255, 255, 255, 0.50)',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '124%',
        letterSpacing: '0.2px',
        textTransform: 'uppercase',
      },
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        '& $value': {
          background: '#604C87',
          color: 'rgba(255, 255, 255, 1)',
        },
      },
    },
    infoField: {
      width: '100%',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 20px',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '27px',
    },
    bold: {
      color: '#fff',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '170%',
      letterSpacing: '0.6px',
    },
    generalInfoBlock: {
      margin: '1px 0px',
      background: '#1E1D36',
      width: '100%',
      height: 'fit-content',
      padding: '20px 0px',
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    generalInfoField: {
      width: '100%',
      height: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 20px',
    },
    fee: {
      marginTop: '15px',
    },
    total: {
      background: '#1E1D36',
      width: '100%',
      height: '60px',
      borderRadius: '0px 0px 12px 12px',
      padding: '10px 0px',
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    infoBlock: {},
    fieldTitle: {
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
    },
    value: {
      color: 'rgba(255,255,255,0.8)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.2px',
    },
    declineInfoBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
      minHeight: '60px',
      borderRadius: '12px',
      border: `1px solid #1E1D36`,
      background: '#1E1D36',
      padding: '16px',
      position: 'relative',
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        border: `1px solid #412D68`,
      },
    },
    declineInfoLabel: {
      color: 'rgba(255, 255, 255, 0.80)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
    },
    declineInfoTree: {},
    declineInfoMessage: {
      color: 'rgba(255, 255, 255, 0.50)',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3p',
      padding: '14px 0px',
    },
    transactionIdBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '60px',
      borderRadius: '12px',
      border: `1px solid #1E1D36`,
      background: '#1E1D36',
      padding: '16px',
      position: 'relative',
      marginTop: '10px',
      '&.indent': {
        marginBottom: '66px',
      },
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
        border: `1px solid #412D68`,
      },
    },
    transactionIdLabel: {
      color: 'rgba(255, 255, 255, 0.80)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
    },
    transactionIdTree: {},
    transactionId: {
      width: '100%',
      color: 'rgba(255, 255, 255, 0.50)',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      paddingTop: '14px',
      textTransform: 'uppercase',
      background: `url(${copyIcon}) no-repeat right center`,
      backgroundPositionY: '17px',
      cursor: 'pointer',
    },
    secure3D: {
      paddingTop: '20px',
    },
    blockchainButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '60px',
      width: '100%',
      borderRadius: '12px',
      border: '1px solid #7543FF',
      margin: '10px 0px',
      textDecoration: 'none',
      color: 'rgba(255, 255, 255, 0.80)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
    },
    loading: {
      minHeight: '732px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [breakpoints.down(Layout.laptop)]: {
        minHeight: '662px',
      },
    },
    recipient: {
      marginBottom: '10px',
    },
    paymentMethod: {
      height: '60px',
      marginTop: '10px',
      [breakpoints.up(Layout.laptop)]: {
        '& .wallet-root': {
          background: '#412D68',
        },
        '& .card-root': {
          background: '#412D68',
        },
      },
    },
  });
}
