import React, { FC } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import NewTransferForm from '../new-transfer-form/new-transfer';

import { styles } from './new-transfer-mobile.styles';

export interface NewTransferMobileProps extends AppWithStyles<typeof styles> {}

const NewTransferMobileComponent: FC<NewTransferMobileProps> = appObserver(({ classes }) => {
  return (
    <div className={classes.root}>
      <NewTransferForm />
    </div>
  );
});
export default appWithStyles(styles)(NewTransferMobileComponent);
